<template>
    <div id="page-customer-details" :class="{ 'on-sidebar': on_sidebar }">
        <app-loader v-if="loading" fixed-on="desktop" />

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="base-info">
                        <router-link v-if="uuid" class="name" :to="{ name: 'customer', params: { uuid } }">
                            <span>{{ fullname }}</span>
                        </router-link>

                        <div class="status">Account status: <span class="label" :class="[status.value]">{{ status.text }}</span></div>
                    </div>

                    <app-table-details
                        title="Personal information"

                        :rows="rows.personal_information"
                        :data="personal_information"
                    />

                    <div class="shadow">
                        <app-customer-event-log
                            :customer_uuid="uuid"

                            ref="customer-event-log"

                            @refresh-comments-list="refreshCommentsList"
                        />

                        <app-comments
                            :customer_uuid="uuid"

                            :with-pagination="false"

                            ref="comments"
                        />
                    </div>

                    <router-link v-if="uuid" class="btn btn-secondary btn-full-width" :to="{ name: 'customer', params: { uuid } }">Go to customer page</router-link>
                    <button v-else class="btn btn-secondary btn-full-width" disabled>Go to customer page</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import appLoader from '@/components/app-loader'
import appTableDetails from '@/components/app-table-details'
import appCustomerEventLog from '@/components/app-customer-event-log'
import appComments from '@/components/app-comments'

export default {
    props: {
        uuid: { type: String, default: null },
    },

    components: {
        appLoader,
        appTableDetails,
        appCustomerEventLog,
        appComments,
    },

    data() {
        return {
            customer: {},

            rows: {
                personal_information: [
                    { key: 'FirstName',    title: 'First name'     },
                    { key: 'LastName',     title: 'Last name'      },
                    { key: 'MobileNumber', title: 'Contact number' },
                    { key: 'Email',        title: 'E-mail'         },
                    { key: 'UUID',         title: 'Account ID'     },
                    { key: 'Active',       title: 'Active'         },
                ],
            },

            loading: false,

            on_sidebar: false,
        }
    },

    created() {
        this.init()
    },

    mounted() {
        this.on_sidebar = this.$parent.$el.classList.contains('app-sidebar-details')
    },

    methods: {
        init() {
            this.loadCustomerInfo()
        },

        loadCustomerInfo() {
            if (this.uuid) {
                this.loading = true

                this.$store.dispatch('getCustomerByUUID', { uuid: this.uuid }).then(customer => {
                    this.customer = customer

                    this.loading = false
                }).catch(error => {
                    this.closeSidebar()

                    this.loading = false
                })
            }
        },

        closeSidebar() {
            this.$emit('close-sidebar')
        },

        refreshCommentsList() {
            this.$refs.comments.search()
        },

        reset() {
            this.$refs['customer-event-log'].reset()
        },
    },

    computed: {
        fullname() {
            const fullname = [this.customer.FirstName, this.customer.LastName].join(' ').trim()

            return fullname.length ? fullname : '?'
        },

        status() {
            return this.loading ? {
                text: 'Loading',
                value: 'info',
            } : (
                this.customer.Active ? {
                    text: 'Active',
                    value: 'success',
                } : {
                    text: 'Inactive',
                    value: 'danger',
                }
            )
        },

        personal_information() {
            return {
                FirstName: this.customer.FirstName ? this.customer.FirstName : '?',
                LastName: this.customer.LastName ? this.customer.LastName : '?',
                MobileNumber: this.customer.MobileNumber ? this.customer.MobileNumber : '?',
                Email: this.customer.Email ? this.customer.Email : '?',
                UUID: this.customer.UUID ? this.customer.UUID : '?',
                Active: this.customer.Active ? 'True' : 'False',
            }
        },
    },

    watch: {
        uuid() {
            this.loadCustomerInfo()

            this.reset()
        },
    },
}
</script>

<style lang="scss">
#page-customer-details {
    .base-info {
        display: flex;
        justify-content: space-between;

        margin-bottom: 64px;

        .name {
            display: flex;
            flex: 1;
            position: relative;
            min-width: 0;
            max-width: fit-content;
            margin-right: 32px;
            padding-right: 8px + 18px;
            font-size: 32px;
            font-weight: bold;
            line-height: 40px;
            color: var(--color-sidebar-link);
            transition: color $transition-duration-primary;
            cursor: pointer;

            span {
                @include text-overflow();
            }

            &::before {
                position: absolute;
                right: 0;
                bottom: 5px;
                display: block;
                width: 24px;
                height: 24px;

                color: var(--color-icon-action);

                @include icon($icon-account-details);
            }


            &::after {
                position: absolute;
                left: 0;
                bottom: -4px;
                display: block;
                width: 0;
                height: 4px;
                background-color: var(--color-tab-hover);
                border-radius: $border-radius-primary;
                transition: width $transition-duration-primary;
                content: '';
            }

            &:hover {
                color: var(--color-tab-hover);

                &::after {
                    width: 80px;
                }
            }
        }

        .status {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
            line-height: 24px;
            color: var(--color-sidebar-text);

            .label {
                margin-left: 16px;
                padding: 4px 12px;
                font-size: 16px;
                font-weight: bold;
                text-align: center;
                border-radius: $border-radius-primary;

                @each $type in $table-tag-types {
                    &.#{ $type } {
                        color: map-get($table-tag-colors, $type);
                        background-color: map-get($table-tag-backgrounds, $type);
                    }
                }
            }
        }
    }

    .app-table-details {
        margin-bottom: 30px;

        h2 {
            font-size: 24px;
            line-height: 32px;
        }
    }

    .shadow {
        margin-bottom: 30px;

        background-color: var(--color-component-bg-primary);
        border-radius: $border-radius-primary;
        box-shadow: var(--box-shadow-secondary);
    }

    &.on-sidebar {
        margin: 36px 0 40px;
    }
}

@media (max-width: $tablet-size) {
    #page-customer-details {
        .base-info {
            .name {
                margin-right: 21px;
            }

            .status {
                font-size: 14px;

                .label {
                    margin-left: 8px;
                    padding: 2px 8px;

                    font-size: 12px;

                    text-transform: uppercase;
                }
            }
        }
    }
}
</style>
<template>
    <base-layout>
        <template v-slot:sidebar>
            <app-sidebar-details title="Customer details" @close-sidebar="closeSidebar">
                <customer-details
                    :uuid="customer_uuid"

                    @close-sidebar="closeSidebar"
                />
            </app-sidebar-details>
        </template>

        <div class="page-main-dashboard container" v-if="!has_role_customers">
            <div class="row">
                <div class="col-12">
                    <h1 class="heading">Main Dashboard</h1>

                    <h2>Welcome to the portal. Please select an item from the left menu to proceed</h2>
                </div>
            </div>
        </div>

        <div class="page-main-dashboard container" v-if="has_role_customers">
            <div class="row">
                <div class="col-12">
                    <h1 class="heading">Main Dashboard</h1>

                    <app-table
                        class="customers-table"

                        :sort-value="customers.filter.sort"
                        :sort-options="customers.sort"
                        @sort-change="changeCustomersSort"

                        :clickable="true"
                        :selected-row="customers.selected_row"

                        route="customers"

                        title="Customers"

                        :cols="customers.cols"
                        :rows="customers.list"

                        :loading="customers.loading"

                        @row-click="showCustomerDetails($event, 'customers')"
                        @row-click-mobile="openCustomerDetails"
                    />
                </div>

                <template v-if="show_services">
                    <template v-for="(service_config, type) in services_сonfig">
                        <div class="col-tab-12" :class="service_config.banner ? 'col-8' : 'col-12'" :key="`${ type }-table`">
                            <app-table
                                :class="`${ type }-services-table`"

                                :cols="service_config.cols"
                                :rows="services[type].list"

                                :title="service_config.title"

                                :sort-value="services[type].filter && services[type].filter.sort"
                                :sort-options="service_config.sort"
                                @sort-change="changeServiceSort($event, type)"

                                :selected-row="services[type].selected_row"

                                :route="service_config.route"

                                :loading="services[type].loading"

                                @open-sidebar="showCustomerDetails($event, type)"
                                @remove-device="openDialogServiceRemoving($event, type)"
                                @row-click-mobile="openCustomerDetails"
                            ></app-table>
                        </div>

                        <div class="col-4 col-tab-12" v-if="service_config.banner" :key="`${ type }-banner`">
                            <app-adding-banner-service :service="service_config.banner" />
                        </div>
                    </template>
                </template>
            </div>

            <dialog-service-removing
                v-if="dialogs.removing.show"
                v-model="dialogs.removing.show"

                :uuid="dialogs.removing.uuid"
                :service="dialogs.removing.service"

                @closes="onClosesDialogRemoveDevice"
                @confirm="onServiceRemoved"
            ></dialog-service-removing>
        </div>
    </base-layout>
</template>

<script>
import { mapGetters } from 'vuex'

import baseLayout from '@/views/layouts/base-layout'
import appSidebarDetails from '@/components/app-sidebar-details'

import customerDetails from '@/views/customer-details'

import appTable from '@/components/app-table'
import appAddingBannerService from '@/components/app-adding-banner-service'

import dialogServiceRemoving from './dialogs/dialog-service-removing'

import formatDateNano from '@/helpers/format-date-nano'

const AVAILABLE_SERVICES = {
    fwa: {
        config: {
            title: 'Fixed Wireless',
            route: 'fwa-services',
            banner: 'FWA',

            sort: [
                { field: 'State', title: 'State' },
            ],

            cols: [
                { key: 'uuid',   title: 'Service UUID', highlight: true          },
                { key: 'IMSI',   title: 'IMSI'                                   },
                { key: 'MSISDN', title: 'MSISDN'                                 },
                { key: 'State',  title: 'State',        sort: { field: 'State' } },
            ],
        },

        data: {
            list: [],

            loading: false,

            filter: {
                sort: {
                    field: 'State',
                    order: 'asc'
                },
            },

            pagination: {
                // page: 1, // pagination not enabled
                total: 0,
                limit: 7,
                range: 2,
            },

            selected_row: null,
        },

        alias: 'FWAProducts',
        action: 'api_fwa/FindFWAProductsPaginated',
        mapping: (service) => ({
            uuid: service.UUID,
            IMSI: service.IMSI ? service.IMSI : '?',
            MSISDN: service.MSISDN ? service.MSISDN : '?',
            State: service.State ? service.State : '?',
        }),
    },

    fibre: {
        config: {
            title: 'Fibre',
            route: 'fibre-services',
            banner: 'Fibre',

            sort: [
                { field: 'Name',   title: 'Name'   },
                { field: 'Type',   title: 'Type'   },
                { field: 'Family', title: 'Family' },
            ],

            cols: [
                { key: 'name',          title: 'Name',        sort: { field: 'Name'         }, highlight: true                      },
                { key: 'serial-number', title: 'Serial №',    sort: { field: 'SerialNumber' }                                       },
                { key: 'household-id',  title: 'Household ID'                                                                       },
                { key: 'type',          title: 'Type',        sort: { field: 'Type'         }                                       },
                { key: 'family',        title: 'Family',      sort: { field: 'Family'       }                                       },
                { key: 'link',                                action: 'open-sidebar',          icon: 'link',   behavior: 'hidden'   },
                { key: 'remove',                              action: 'remove-device',         icon: 'remove', behavior: 'detached' },
            ],
        },

        data: {
            list: [],

            loading: false,

            filter: {
                sort: {
                    field: 'Family',
                    order: 'asc'
                },
            },

            pagination: {
                // page: 1, // pagination not enabled
                total: 0,
                limit: 10,
                range: 2,
            },

            selected_row: null,
        },

        action: 'getFibreServices',
        mapping: (service) => ({
            uuid: service.UUID,
            'customer-uuid': service.CustomerUUID,
            name: service.Name,
            'serial-number': service.SerialNumber,
            'household-id': service.HouseholdId,
            type: service.Type,
            family: service.Family,
        }),
    },

    mobile: {
        config: {
            title: 'PostPay Mobile',
            route: 'postpay-mobile-services',
            banner: 'PostPay Mobile',

            sort: [
                { field: 'State',  title: 'State'  },
                { field: 'IMSI',   title: 'IMSI'   },
                { field: 'MSISDN', title: 'MSISDN' },
            ],

            cols: [
                { key: 'uuid',   title: 'Service UUID', highlight: true           },
                { key: 'IMSI',   title: 'IMSI',         sort: { field: 'IMSI'   } },
                { key: 'MSISDN', title: 'MSISDN',       sort: { field: 'MSISDN' } },
                { key: 'State',  title: 'State',        sort: { field: 'State'  } },
            ],
        },

        data: {
            list: [],

            loading: false,

            filter: {
                sort: {
                    field: 'Name',
                    order: 'asc'
                },
            },

            pagination: {
                // page: 1, // pagination not enabled
                total: 0,
                limit: 7,
                range: 2,
            },

            selected_row: null,
        },

        alias: 'PostpayProducts',
        action: 'api_postpay/FindPostpayProductsPaginated',
        mapping: (service) => ({
            uuid: service.UUID,
            IMSI: service.IMSI ? service.IMSI : '?',
            MSISDN: service.MSISDN ? service.MSISDN : '?',
            State: service.State ? service.State : '?',
        }),
    },
}

export default {
    props: {
        search: { type: Object, default: () => ({ word: '', option: null })}
    },

    components: {
        baseLayout,
        appSidebarDetails,

        customerDetails,

        appTable,
        appAddingBannerService,

        dialogServiceRemoving,
    },

    data() {
        return {
            customer_uuid: null,

            customers: {
                selected_row: null,

                list: [],

                filter: {
                    word: '',
                    previous: '',
                    sort: {
                        field: 'FirstName',
                        order: 'asc'
                    },
                },

                sort: [
                    { field: 'FirstName',    title: 'Name'              },
                    { field: 'Active',       title: 'Status'            },
                    { field: 'CreatedAt',    title: 'Registration date' },
                    { field: 'Email',        title: 'Email'             },
                ],

                cols: [
                    { key: 'name',         title: 'Name',           sort: { field: 'FirstName' }, highlight: true,                 },
                    { key: 'email',        title: 'Email',          sort: { field: 'Email'     }                                   },
                    { key: 'ExternalRef1', title: 'External Ref# 1'                                                                },
                    { key: 'status',       title: 'Status',         sort: { field: 'Active'    },                  type: 'status', },
                    { key: 'registration', title: 'Created At',     sort: { field: 'CreatedAt' }                                   },
                ],

                pagination: {
                    // page: 1, // pagination not enabled
                    total: 0,
                    limit: 6,
                    range: 2,
                },

                loading: false,
            },

            services: {},

            dialogs: {
                removing: {
                    show: false,
                    uuid: null,
                    service: null,
                },
            },
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {

            if (this.has_role_customers) {
                this.getCustomers()
            }

            if (this.has_role_fwa) {
                if (this.show_services) {
                    for (const type in this.services_сonfig) {
                        this.getServices(type)
                    }
                }
            }
        },



        /**
         * Customers
         */
        getCustomers(filter) {
            this.customers.loading = true

            filter = {
                ...this.customers.filter,
                ...(this.search.option == 'customers' ? this.search : {}),
                ...filter
            }

            let params = {
                'IncludeInactive': false,
                'SearchOptions.PageSize': this.customers.pagination.limit,
                'SearchOptions.SortBy': filter.sort.field,
                'SearchOptions.SortDesc': filter.sort.order === 'desc' ? 1 : 0,
            }

            const word = filter.word.trim()

            if (word.length > 0) {
                params = {
                    ...params,
                    'SmartSearch': word,
                }

                this.customers.filter.word = word
                this.customers.filter.previous = word
            }

            /**
             * Sometimes the API sends "CreatedAt" field in nanoseconds, sometimes in seconds
             */

            const billion = Math.pow(10, 9)

            /**
             * Number of milliseconds passed from Jan 01 1970 00:00:00 to Jan 01 2100 00:00:00
             */
            const milliseconds = Date.UTC(2100, 0)

            /**
             * Number of seconds passed from Jan 01 1970 00:00:00 to Jan 01 2100 00:00:00
             */
            const seconds = milliseconds / 1000

            this.$store.dispatch('getCustomers', { params, filter }).then(({apidata}) => {
                this.customers.list = apidata.Customers.map(customer => ({
                    uuid: customer.UUID,

                    'customer-uuid': customer.UUID,

                    name: this.fullname(customer.FirstName, customer.LastName),

                    email: customer.Email,

                    ExternalRef1: customer.ExternalRef1,

                    status: {
                        status: customer.Active ? 'success' : 'danger',
                        text: customer.Active ? 'Active' : 'Inactive',
                    },

                    registration: customer.CreatedAt
                        ? customer.CreatedAt < seconds ? formatDateNano(customer.CreatedAt * billion) : formatDateNano(customer.CreatedAt)
                        : '?',
                }))

                this.customers.loading = false
            }).catch(error => {
                console.error(error)

                this.customers.list = []

                this.customers.loading = false
            })

            // console.groupEnd()
        },

        fullname(firstname, lastname) {
            const fullname = [firstname, lastname].join(' ').trim()

            return fullname.length ? fullname : '?'
        },

        /**
         * Services
         */
        getServices(type) {
            if (type in this.services) {
                const service = this.services[type]

                /**
                 * if testing data are provided, then no need to get data per API
                 */
                if (service.TEST_list) {
                    const delay_in_ms = 1000

                    service.loading = true

                    setTimeout(() => {
                        this.services[type].list = service.TEST_list

                        service.loading = false
                    }, delay_in_ms)

                    return
                }

                if (AVAILABLE_SERVICES[type].action) {
                    service.loading = true

                    const filter = {
                        ...service.filter,
                    }

                    const params = {
                        SPID: this.current_spid,

                        'SearchOptions.PageSize': service.pagination.limit,
                        'SearchOptions.SortBy': service.filter.sort.field,
                        'SearchOptions.SortDesc': service.filter.sort.order === 'desc' ? 1 : 0,
                    }

                    this.$store.dispatch(AVAILABLE_SERVICES[type].action, params).then(response => {
                        const Services = response[AVAILABLE_SERVICES[type].alias ? AVAILABLE_SERVICES[type].alias : 'Services']

                        this.services[type].list = AVAILABLE_SERVICES[type].mapping
                            ? Array.isArray(Services) ? Services.slice(0, AVAILABLE_SERVICES[type].data.pagination.limit).map(AVAILABLE_SERVICES[type].mapping) : []
                            : Services
                    }).catch(error => {
                        console.warn(`Error ${ AVAILABLE_SERVICES[type].action }`, error)
                        console.dir(error)

                        service.services = []
                    }).finally(() => {
                        service.loading = false
                    })
                }
            }
        },



        /**
         * Filtering
         */
        onSearch(search) {
            switch (search.option) {
                case 'customers': {
                    if (search.word.trim() != this.customers.filter.word.trim()) {
                        this.getCustomers({ word: search.word })
                    }
                } break

                // case 'fwa': {} break
                // case 'fibre': {} break
                // case 'mobile': {} break
            }
        },

        changeCustomersSort(sort) {
            this.customers.filter.sort = sort
            this.getCustomers()
        },

        changeServiceSort(sort, type) {
            this.services[type].filter.sort = sort
            this.getServices(type)
        },



        /**
         * Remove Device
         */
        openDialogServiceRemoving({ uuid }, service) {
            this.dialogs.removing.show = true
            this.dialogs.removing.uuid = uuid
            this.dialogs.removing.service = service
        },

        onClosesDialogRemoveDevice() {
            this.dialogs.removing.show = false
            this.dialogs.removing.uuid = null
            this.dialogs.removing.service = null
        },

        onServiceRemoved() {
            this.getServices(this.dialogs.removing.service)
        },

        

        /**
         * Details
         */
        openCustomerDetails(row) {
            const uuid = row['customer-uuid'] || row.uuid

            this.$router.push({ name: 'customer-details', params: { uuid } })
        },

        showCustomerDetails(row, table) {
            const row_uuid = row.uuid
            const customer_uuid = row['customer-uuid'] || row_uuid

            if (this.tables[table].selected_row == row_uuid && this.customer_uuid == customer_uuid) {
                this.closeSidebar() // если кликнули ту же таблицу и тот же ряд
            } else {
                this.customer_uuid = customer_uuid // установить UUID клиента для правого сайдбара

                for (const key in this.tables) {
                    this.tables[key].selected_row = table == key
                        ? row_uuid  // если кликнули в этой таблице, то устанавливаем selected_row
                        : null      // если кликнули в другой таблице, то обнуляем selected_row
                }

                this.openSidebar() // теперь можно открывать правый сайдбар с данными о клиенте
            }
        },

        openSidebar() {
            this.$emit('open-sidebar')
        },

        closeSidebar() {
            this.customer_uuid = null

            for (const key in this.tables) {
                this.tables[key].selected_row = null
            }

            this.$emit('close-sidebar')
        },
    },

    computed: {
        ...mapGetters([
            'modules_user',
            'current_spid',
            'theme',
            'isModuleAvailable',
            'is_super_admin',
            'current_sp_roles',
        ]),

        has_role_customers() {
            return this.is_super_admin || this.current_sp_roles.includes('READ') || this.current_sp_roles.includes('WRITE')
        },

        has_role_fwa() {
            return this.is_super_admin || this.current_sp_roles.includes('PROD_FWA') || this.current_sp_roles.includes('FWA.READ') || this.current_sp_roles.includes('FWA.WRITE')
        },

        show_services() {
            return this.theme != 'pb'
        },

        services_сonfig() {
            let services_сonfig = {}

            const services_available = {
                fwa:    Boolean(this.modules_user.PROD_FWA) && this.isModuleAvailable('PROD_FWA'),
                fibre:  Boolean(this.modules_user.PROD_FIBRE) && this.isModuleAvailable('PROD_FIBRE'),
                mobile: Boolean(this.modules_user.PROD_MOBILE) && this.isModuleAvailable('PROD_MOBILE'),
            }
            
            let is_service_found = false
            const show_only_first_available = false

            for (const type in services_available) {
                if (AVAILABLE_SERVICES[type] && services_available[type] && !(show_only_first_available && is_service_found)) {
                    services_сonfig[type] = AVAILABLE_SERVICES[type].config

                    this.$set(this.services, type, AVAILABLE_SERVICES[type].data)

                    is_service_found = true
                } else {
                    this.$delete(this.services, type)
                }
            }
            
            return services_сonfig
        },

        tables() {
            return {
                customers: this.customers,
                ...this.services
            }
        },
    },

    beforeRouteLeave(to, from, next) {
        this.closeSidebar()
        next()
    },
}
</script>

<style lang="scss">
.page-main-dashboard {
    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .app-table,
    .app-adding-banner {
        margin-bottom: 30px;
    }

    .customers-table {
        @include table-cols-width((175px, 270px, 150px, 105px, 140px), true);
    }

    .fwa-services-table {
        @include table-cols-width((275px, 150px, 150px, 150px), true);
    }

    .fibre-services-table {
        @include table-cols-width((150px, 150px, 150px, 100px, 150px, 50px, 50px), true);
    }

    .mobile-services-table {
        @include table-cols-width((180px, 110px, 130px, 150px, 50px, 50px), true);
    }
}

@media (max-width: $tablet-size) {
    .page-main-dashboard {
        .customers-table {
            @include table-cols-width((150px, 230px, 130px, 75px, 115px), true);
        }

        .fwa-services-table {
            @include table-cols-width((275px, 150px, 150px, 150px), true);
        }

        .fibre-services-table {
            @include table-cols-width((150px, 80px, 110px, 50px, 110px, 50px, 50px), true);
        }

        .mobile-services-table {
            @include table-cols-width((140px, 135px, 135px, 150px, 50px, 50px), true);
        }
    }
}

@media (max-width: $mobile-size) {
    .page-main-dashboard {
        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

        .customers-table {
            margin-bottom: 15px;

            @include table-cols-width-mobile((95px, 180px), true);
        }

        .fwa-services-table {
            margin-bottom: 15px;

            @include table-cols-width-mobile((110px, 165px), true);
        }

        .fibre-services-table {
            margin-bottom: 15px;

            @include table-cols-width-mobile((80px, 165px, 24px), true);
        }

        .mobile-services-table {
            margin-bottom: 15px;

            @include table-cols-width-mobile((100px, 145px, 24px), true);
        }
    }
}
</style>